.fr.tickets.groupper table.fr.table{
	border-top: 1px solid #e5e7eb !important;
	// tr{
	// 	td{
	// 		padding-top: 0;
	// 	}
	// 	&:first-child{
	// 		td{
	// 			padding-top: 10px;
	// 		}
	// 	}
	// 	td:first-child{
	// 		text-align: right;
	// 	}
	// 	td:last-child{
	// 		text-align: right;
	// 		font-weight: bold;
	// 	}
	// }
	&.totals{
		td:first-child{
			text-align: right;
		}
		td:last-child{
			font-weight: bold;
			text-align: right;
		}
	}
}

.fr.tickets.items{
	.item{
		display: flex;
		img{
			width: 80px;
			height: 80px;
			display: block;
			border-radius: 8px;
			border: 1px solid rgba(0,0,0,0.1)
		}
		.info{
			padding: 0 10px;
			font-size: 14px;
			line-height: 18px;

			.event{
				font-size: 16px;
				line-height: 20px;
				font-weight: bold;
			}
			.meta{
				color: gray;
			}
			.cost{
				font-weight: bold;
				color: #c51818;
			}
			.paid{
				font-weight: bold;
				color: green;
			}
		}
	}
	&.small .item img{
		width: 60px;
		height: 60px;
	}
}