.fr.mytickets-screen{
	> div > .fr.header{
		margin-bottom: 15px !important;
	}
	.fr.groupper{
		> .head{
			font-size: 20px;
			padding: 25px;
		}
		margin-bottom: 15px !important;
		text-align: center;
	}
}