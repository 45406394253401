body{
	background-color: #f5f5f5;
}

.ar.navbar{
	position: fixed;
	width: 100%;
	width: 100vw;
	background-color: white;
	border-bottom: 2px solid #e9e9e9;
	display: flex;
	justify-content: space-between;
	height: 60px;
	z-index: 1000;

	&.search{
		border-bottom-color: white;
		.right > .item:not(.search){
			display: none !important;
		}
		> .right, > .left{
			width: 200px;
		}
	}
	&:not(.search){
		* > .item.search{
			display: none !important;
		}
	}

	.left{
		padding-left: 15px;
		.hamburger.item{
			min-width: 0;
			padding: 0;
			font-size: 26px;
			width: 40px;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: center;
			&:hover{
				background-color: transparent;
			}
		}
		.logo.item{
			padding: 0;
			height: 23px;
			img{
				height: 23px;
			}
			&:hover{
				background-color: transparent;
			}
			> .icon{
				display: none;
				height: 30px;
				margin-top: -3px
			}
		}
	}
	.center{
		flex-grow: 100;
		justify-content: center;
		.fr.field, .fr.input{
			width: 100%;
			max-width: 500px;
			margin: 0;
			input{
				background-color: #f5f5f5;
				border-color: darken(#f5f5f5, 2);
				
				&:focus{
					border-color: darken(#f5f5f5, 5);
				}
			}
		}
		> .item.icon{
			display: none;
			i.icon{
				font-size: 20px;
			}
		}
	}
	.right{
		justify-content: flex-end;
		padding-right: 10px;
	}
	> *{
		display: flex;
		align-items: center;
		width: 320px;
		// margin: 0 10px;
	}
	* > .item{
		padding: 8px 15px;
		text-decoration: none;
		color: black;
		border-radius: 8px;
		min-width: 80px;
		text-align: center;
		font-weight: bold;
		margin: 0 5px;
		&:hover{
			background-color: #f1f1f1;
			cursor: pointer;
		}

		&.icon{
			padding: 0;
			min-width: 40px;
			border-radius: 100px;
			height: 40px;
			> i.icon{
				margin: 0;
				font-size: 24px;
				line-height: 40px;
			}
		}

		&.show-search{
			background-color: #f0f0f0;
			width: 150px;
			i.icon{
				margin-right: 10px;
			}
		}
	}

	&:not(.search){
		@media screen and (max-width: 900px){
			.left{
				width: 200px;
			}
		}
		@media screen and (min-width: 741px){
			.right .show-search.item{
				display: none !important;
			}
		}
		@media screen and (max-width: 740px){
			.right{
				width: auto;
			}
			.center .fr.input{
				display: none;
			}
		}
		@media screen and (max-width: 620px){
			.right .item{
				margin: 0 1px;
				&.show-search{
					width: 120px;
				}
			}
		}
		@media screen and (max-width: 520px){
			.right{
				display: none;
			}
			.center{
				padding-right: 5px;
				justify-content: flex-end;
				.fr.input{
					display: none;
				}
				.item.icon{
					display: block;
				}
			}
		}
	}
	@media screen and (min-width: 521px){
		.left .hamburger.item{
			display: none;
		}
	}

	&.search{
		@media screen and (max-width: 780px){
			> .right{
				width: auto;
				padding-right: 5px;
			}
		}
		@media screen and (max-width: 600px){
			> .left{
				width: auto;
				> .hamburger{
					display: none;
				}
				> .logo.item{
					width: 50px;
					min-width: 50px;
					.large{
						display: none;
					}
					.icon{
						display: block;
					}
				}
			}
			> .center{
				margin-left: 15px;
			}
			> .right{
				width: auto;
				padding-right: 5px;
			}
		}
	}
}

.ar.search-container{
	position: fixed;
	top: 60px;
	// border-radius: 12px;
	// left: 10px;
	// background-color: white;
	// width: calc(100% - 20px);
	// width: calc(100vw - 20px);
	width: 100%;
	width: 100vw;
	height: 50%;
	max-height: 400px;
	z-index: 999;
	padding: 10px;
	display: flex !important;
	justify-content: center;

	.contents{
		min-height: 400px;
		z-index: 1;
		max-width: 840px;
		padding: 20px 20px 25px 20px;
		background-color: white;
		border-radius: 12px;
		width: 100%;
		height: 100%;

		display: flex;
		justify-content: space-between;

		> * > .title{
			font-weight: bold;
			font-size: 18px;
			margin-bottom: 10px;
		}

		> .left{
			width: 45%;
			height: 100%;
			// padding: 15px;

			.trends .trend{
				display: inline-block;
				background-color: #EEE;
				padding: 10px 15px;
				margin: 0 5px 5px 0;
				border-radius: 6px;
				font-weight: bold;
				text-transform: uppercase;
				cursor: pointer;

				&:hover{
					background-color: darken(#EEE, 2);
				}

				&.placeholder{
					height: 40px;
					width: 80px;
					color: transparent;
					user-select: none;
					padding: 15px 15px;

					> * {
						border-radius: 10px;
						margin: auto;
						height: 10px;
						width: 80%;
						background-color: darken(#EEE, 2);
					}
				}
			}
		}

		> .right{
			flex-grow: 100;
			// padding: 15px;
		}

		> .results{
			width: 100%;
			> .events{
				margin-top: 15px;
				display: flex;
				width: 100%;
				> .left{
					margin-right: 10px;
				}
				> * {
					width: 50%;
				}
				> .empty{
					width: 100%;
					min-height: 300px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				@media screen and (max-width: 720px){
					display: block;
					> .left{
						margin-right: 0;
						margin-bottom: 8px;
					}
					> * {
						width: 100% !important;
					}
				}
			}
		}

		.ar.search-event{
			margin: 8px 0;
			display: flex;
			align-items: center;

			.fr.placeholder{
				display: block !important;
				margin: 5px 0 !important;
			}

			&:not(.placeholder):hover{
				cursor: pointer;
				.data{
					background-color: #f0f0f0;
					border-radius: 12px;
				}
			}

			&:first-child{
				margin-top: 0;
			}
			&:last-child{
				margin-bottom: 0;
			}
			img,.image.placeholder{
				width: 90px;
				height: 90px;
				min-width: 90px;
				background-color: #EEE;
				border-radius: 12px;
			}
			.data{
				color: black;
				flex-grow: 100;
				margin-left: 5px;
				padding: 5px 10px;
				height: 90px;
				display: flex;
				flex-direction: column;
				justify-content: center;

				.title{
					font-weight: bold;
					font-size: 16px;
					width: 280px;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
				.meta{
					color: gray;
				}
			}
		}
	}

	> .overlay{
		background-color: transparent;
		z-index: 0;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
	}

	&.results{
		height: auto;
		max-height: 100%;
	}

	@media screen and (max-width: 600px){
		height: auto;
		max-height: 100%;
		&:not(.results) .contents{
			flex-direction: column-reverse;
		}
		.contents{
			> .left{
				margin-top: 20px;
			}
			> *{
				width: 100% !important;
			}

			.ar.search-event .data{
				.title{
					white-space: wrap;
					width: auto;
				}
			}
		}
	}
}

.ar.search-overlay{
	z-index: 998;
	background-color: #151717be;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.ar.navsidebar{
	position: fixed;
	top: 60px;
	left: 0;
	width: 75%;
	max-width: 300px;
	background-color: #151717;
	height: calc(100vh - 60px);
	overflow-y: scroll;
	padding-top: 5px;
	z-index: 1000;
	.item{
		min-width: 280px;
		font-size: 24px;
		padding: 15px;
		cursor: pointer;
		display: block;
		color: white;
		border-bottom: 1px solid lighten(#151717, 5);
		&.dropdown{
			padding: 0;
			.content{
				display: flex;
				align-items: center;
				.text{
					flex-grow: 100;
					padding: 15px;
				}
				i.dropdown.icon{
					margin-right: 10px;
					padding-left: 10px;
					margin-right: 15px;
				}
			}
			.menu{
				.item{
					display: block;
					background-color: #1e1e24;
					padding: 15px;
					font-size: 18px;
					color: white;
					margin-bottom: 0;
				}
			}
		}
	}
}

.ar.closable{
	position: fixed;
	top: 60px;
	left: 0;
	width: 100%;
	height: calc(100vh - 60px);
	background-color: rgba(0,0,0,0.8);
	z-index: 999;
}

.ar.event-item{
	width: 300px;
	margin: 5px;
	color: black;
	.header{
		border-top-right-radius: 12px;
		border-top-left-radius: 12px;
		background-color: #CCC;
		padding: 8px 15px;
		font-weight: bold;
		font-size: 16px;
		display: flex;
		justify-content: space-between;

		> .placeholder{
			height: 20px;
			background-color: lighten(#CCC, 5);
			border-radius: 100px;
			&.left{
				width: 120px;
			}
			&.right{
				width: 60px;
			}
		}
	}
	img{
		width: 300px;
		height: 300px;
		border: 0;
		box-shadow: none;
	}
	.placeholder.image{
		width: 300px;
		height: 300px;
		background-color: #DDD;
		display: flex;
		align-items: center;
		justify-content: center;
		.loading{
			width: 200px;
			height: 50px;
			background-color: darken(#DDD, 10);
			border-radius: 100px;
		}
	}
	img,.placeholder.image{
		border-bottom-left-radius: 12px;
		border-bottom-right-radius: 12px;
	}
	.info{
		padding: 0px 5px;
		.name{
			font-size: 16px;
			font-weight: bold;
		}
		.meta{
			color: gray;
		}

		> .placeholder{
			background-color: lighten(#CCC, 5);
			border-radius: 100px;
			height: 20px;
			width: 250px;
			max-width: 100%;
			&.meta{
				margin: 2px 0;
				height: 16px;
				width: 100px;
			}
		}
	}
	&.placeholder{
		transition: ease;
		transition-duration: 100ms;
		&.anim{
			.placeholder.image .loading{
				background-color: darken(#DDD, 15);
			}
			.header{
				background-color: darken(#CCC, 5);
			}
		}
		.info{
			padding-top: 8px;
		}
	}
	&:not(.placeholder){
		cursor: pointer;
		&:hover{
			transform: translateY(-2px);
		}
	}
}

.ar.events{
	margin-top: 20px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;

	.ar.event-item{
		@media screen and (max-width: 940px){
			width: 250px;
			img, .placeholder.image{
				width: 250px;
				height: 250px;
				.loading{
					width: 150px;
					height: 30px;
				}
			}
		}
		@media screen and (max-width: 600px){
			width: calc(50vw - 10px);
			img, .placeholder.image{
				width: calc(50vw - 10px);
				height: calc(50vw - 10px);

				.loading{
					width: calc(50vw - 50px);
					height: 25px;
				}
			}
		}
		@media screen and (max-width: 500px){
			.header{
				.left{
					width: 100px;
				}
				.right.placeholder{
					display: none;
				}
			}
		}
		@media screen and (max-width: 400px){
			.header{
				padding: 5px 10px;
				.left{
					font-size: 14px;
				}
				.right.placeholder{
					display: none;
				}
			}
		}
	}
}