@use "sass:map";

$AREMA_COLOR: #FD6A08;

$COLOR_NORMAL: #f0f0f0;
$COLOR_STAGE: #151717;
$COLOR_EMPTY: #FAFAFA;
$BORDER_RADIUS: 8px;

.fr.seatmap{
	width: 100%;
	height: 100%;
	overflow: scroll;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 600px;
	user-select: none;
	
	&.tooled{
		padding-top: 85px;
		overflow: hidden;
	}

	.toolbar{
		width: 100%;
		position: fixed;
		top: 60px;
		padding: 10px;
		background-color: #f5f5f5;
		border-bottom: 1px solid #EEE;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 60px;

		.button{
			margin-right: 5px;
			&:last-child{
				margin-right: 0;
			}
		}
	}
	.seats{
		// padding: 10px;
		margin: auto;
		max-height: 100%;
		max-width: 100%;
		overflow: auto;
		width: 100%;
		height: 100%;

		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	&.small .row .seat{
		width: 55px;
		height: 35px;
	}
	&.tooled .seat.selected{
		border: 2px solid $AREMA_COLOR;
		&.stage,&.stage-normal{
			border-color: $AREMA_COLOR;
		}
	}
	&:not(.tooled) .seat.selected{
		background-color: $AREMA_COLOR;
		color: white;
	}
}

.fr.seatmap-row, .fr.seatmap .row{
	display: flex;
	padding: 0 10px;
	// align-items: center;
	// justify-content: center;
}

.fr.seatmap-seat,.fr.seatmap .row .seat{
	width: 60px;
	min-width: 60px;
	height: 40px;
	margin: 2px;
	border-radius: $BORDER_RADIUS;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: $COLOR_NORMAL;
	padding: 5px;
	cursor: pointer;
	&:hover{
		background-color: #e7e7e7;
	}

	.number,.text{
		font-weight: bold;
		font-size: 14px;
		line-height: 14px;
		display: flex;
		align-items: center;
		font-family: 'Roboto Mono', monospace;
	}
	.section,.subtext{
		font-size: 10px;
		line-height: 10px;
		text-align: center;
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&.empty{
		cursor: auto;
		background-color: $COLOR_EMPTY;
		&:hover{
			background-color: $COLOR_EMPTY;
		}
	}
	&.stage{
		background-color: $COLOR_STAGE;
		cursor: default;
		margin-top: 0;
		margin-bottom: 0;
		border-radius: 0;
		.text{
			color: white;
			font-size: 20px;
			font-weight: bold;
		}
		&.top-left{
			border-top-left-radius: $BORDER_RADIUS;
		}
		&.top-right{
			border-top-right-radius: $BORDER_RADIUS;
		}
		&.bottom-right{
			border-bottom-right-radius: $BORDER_RADIUS;
		}
		&.bottom-left{
			border-bottom-left-radius: $BORDER_RADIUS;
		}
	}
	.meta{
		font-size: 9px;
		margin-top: -8px;
		line-height: 10px;
	}
	// &.bought,&.green{
	// 	background-color: #2ca930;
	// 	color: white;
	// }
	// &.blocked,&.red{
	// 	background-color: brown;
	// 	color: white;
	// }
	// &.locked,&.gray{
	// 	background-color: gray;
	// 	color: white;
	// }
	&.hidden,&.transparent{
		background-color: transparent;
		color: gray;
		display: flex !important;
		&:hover{
			background-color: rgba(0, 0, 0, 0.05);
		}
	}
	// &.special,&.blue{
	// 	background-color: #4d6adf;
	// 	color: white;
	// }
}

$seat-colors: 
	"green" #2ca930 white,
	"red" brown white,
	"purple" #704FAF white,
	"black" #151717 white,
	"blue" #4d6adf white,
	"orange" #F37435 white,
	"white" #f5f5f5 black,
	"yellow" #FFEA03 black,
;

@each $name, $background, $color in $seat-colors{
	.fr.seatmap-seat.#{$name},.fr.seatmap .row .seat.#{$name}{
		color: $color;
		background-color: $background;
		&:hover{
			background-color: darken($background, 5%);
		}
	}
}

.s-noselect{
	-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;
}

#s-rectBox{
	position: absolute;
	z-index: 1090;
	border: 2px dashed #cbd3e3; 
}

.active-select{
	background-color: blue;
	color: #fff;
}