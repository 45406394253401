.ar.nosotros-screen{
	.fr.groupper{
		.section{
			display: flex;

			&.head{
				margin-bottom: 10px;
			}

			ul{
				padding-left: 25px;
				margin-top: 5px;
				margin-bottom: 0;
			}

			.left i.icon{
				display: block;
				text-align: left;
				height: 60px;
				width: 55px;
				margin-right: 5px;
				margin-top: 2px;
				font-size: 50px;


				&.ticket{
					font-size: 40px;
					margin-left: 3px;
					margin-right: 2px;
					transform: rotateZ(-45deg);
				}
				&.flag,&.world{
					font-size: 45px;
				}
				&.handshake{
					font-size: 38px;
				}
				&.hand{
					font-size: 40px;
				}
			}
		}
	}	
}