.ar.event-header{
	justify-content: center;
	display: flex;
	padding: 15px;
	.left{
		img{
			border-radius: 12px;
			width: 350px;
			height: 350px;
			box-shadow: -1px 1px 5px 1px rgba(0,0,0,0.15);
			cursor: pointer;
		}
	}
	.right{
		margin-left: 15px;
		max-width: 500px;
		width: 100%;
		.title{
			font-size: 35px;
			line-height: 40px;
			font-weight: bold;
		}
		.cities{
			color: gray;
			font-size: 16px;
		}
		.sinopsis{
			margin-top: 15px;
			font-size: 16px;
			p{
				margin: 0;
			}
		}
	}
	&.placeholder{
		.right{
			.title{
				height: 34px;
				margin: 3px 0;
				border-radius: 100px;
				background-color: rgba(0,0,0,0.15);
				width: 60%;
				min-width: 350px;
			}
			.cities{
				background-color: rgba(0,0,0,0.08);
				border-radius: 100px;
				height: 20px;
				width: 30%;
				min-width: 150px;
			}
			.sinopsis{
				display: flex;
				flex-wrap: wrap;
				.placeholder{
					background-color: rgba(0,0,0,0.1);
					border-radius: 100px;
					height: 16px;
					margin: 2px 2px 2px 0;
					&.size1{
						width: 100px;
					}
					&.size2{
						width: 142px;
					}
					&.size3{
						width: 72px;
					}
					&.size4{
						width: 81px;
					}
					&.size5{
						width: 23px;
					}
					&.size6{
						width: 112px;
					}
					&.size7{
						width: 192px;
					}
				}
			}
		}
	}
	@media screen and (max-width: 750px){
		flex-direction: column;
		align-items: center;
		.right{
			margin-left: 0px;
			max-width: 600px;
			.title{
				margin-top: 10px;
			}
			.title,.cities{
				text-align: center;
			}
		}
		&.placeholder .right{
			.title{
				margin: 3px auto;
			}
			.cities{
				margin: auto;
			}
		}
	}
	@media screen and (max-width: 450px){
		.left img{
			max-width: 300px;
		}
	}
	@media screen and (max-width: 375px){
		.left img{
			width: 100%;
			height: auto;
			object-fit: contain;
		}
	}
}

.ar.event-countdown{
	display: flex;
	justify-content: center;
	margin-top: 10px;
	margin-bottom: 10px;

	> * {
		// background-color: white;
		padding: 10px 15px;
		font-size: 60px;
		line-height: 60px;
		margin: 0 5px;
		min-width: 100px;
		text-align: center;
		border-radius: 8px;

		&.active{
			font-weight: bold;
			background-color: #FD6A08;
			color: white;
		}
		&:not(.active){
			// color: gray;
		}

		.label{
			font-size: 14px;
			line-height: 16px;
		}
	}
}

.fr.dates.groupper{
	max-width: 600px;
	margin: auto;
	padding: 15px;
	margin-bottom: 50px;

	> .section{
		font-weight: bold;
		font-size: 16px;
		
		width: calc(100% + 30px);
		padding: 10px 15px;
		background-color: rgba(0,0,0,0.05);
		margin-left: -15px;
		margin-bottom: 15px;
		border-top: 1px solid #e5e7eb;
		border-bottom: 1px solid #e5e7eb;
		&.top{
			margin-top: -20px;
		}
		> .placeholder{
			height: 20px;
			width: 42%;

			background-color: rgba(0,0,0,0.05);
			border-radius: 100px;
		}
	}
	> .head{
		width: calc(100% + 32px);
		margin-left: -16px;
		margin-top: -15px;
	}

	&.cal{
		// padding: 0;
		.fr.calendar{
			height: 510px;
			margin: -20px -15px -15px -15px;
			width: calc(100% + 30px);
			border-radius: 0;
			box-shadow: none;

			thead tr th{
				height: 40px;
				border-radius: 0;
			}
			tbody tr td{
				min-width: calc(100% / 7);
				.date,.time{
					height: 70px;
					&.available.date{
						background-color: #f47f3145;
						font-weight: bold;
						font-size: 18px;

						&:hover{
							background-color: #f47f3169 !important;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 600px){
		margin: 0 15px 50px 15px;
		&.cal .fr.calendar{
			height: 400px;
		}
	}

	@media screen and (max-width: 500px){
		margin: 0;
		border-radius: 0;
		.head{
			border-radius: 0;
		}
	}
}

.ar.date{
	display: flex;
	margin-bottom: 10px;
	color: black;
	.left{
		background-color: #F37435;
		color: white;
		text-align: center;
		padding: 10px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		border-top-left-radius: 12px;
		border-bottom-left-radius: 12px;
		min-width: 120px;
		.day{
			font-size: 32px;
			font-weight: 700;
			line-height: 32px;
		}
		.month{
			font-size: 18px;
			font-weight: bold;
		}
	}
	.right{
		flex-grow: 100;
		border-top-right-radius: 12px;
		border-bottom-right-radius: 12px;
		background-color: rgba(0,0,0,0.05);
		padding: 10px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		.time{
			font-size: 24px;
			font-weight: bold;
			margin-bottom: 5px;
		}
		.venue{
			font-size: 18px;
			color: gray;
			line-height: 18px;
			font-weight: bold;
		}
		.status{
			background-color: rgba(0,0,0,0.04);
			color: #323232;
			border-top-right-radius: 12px;
			font-weight: bold;
			margin: -10px -10px 10px -10px;
			padding: 8px 10px;
			font-size: 16px;
		}
	}

	&:hover:not(.placeholder){
		.right{
			background-color: rgba(0,0,0,0.065);
		}
		.left{
			background-color: darken(#F37435, 3%);
		}
	}

	&.inactive{
		.left{
			background-color: rgba(0,0,0,0.13);
			color: #747474;
		}
		.right{
			.time{
				color: #747474;
			}
		}
		&:hover{
			.left{
				background-color: rgba(0,0,0,0.15);
			}
		}
	}

	&.placeholder{
		.left{
			background-color: rgba(0,0,0,0.2);
			.day{
				height: 26px;
				background-color: rgba(0,0,0,0.1);
				width: 60px;
				border-radius: 100px;
				margin: 3px auto;
			}
			.month{
				height: 20px;
				background-color: rgba(0,0,0,0.1);
				border-radius: 100px;
				width: 80%;
				min-width: 80px;
				margin: auto;
			}
		}
		.right{
			.time{
				height: 24px;
				background-color: rgba(0,0,0,0.1);
				width: 60px;
				border-radius: 100px;
			}
			.venue{
				background-color: rgba(0,0,0,0.1);
				border-radius: 100px;
				height: 18px;
				margin-top: -2px;
				width: 50%;
				max-width: 150px;
			}
		}
	}

	@media screen and (max-width: 400px){
		.left{
			min-width: 100px;
			padding: 8px;
		}
	}
}

@media screen and (max-width: 800px){
	.ui.show-image.modal{
		width: 100%;
		.content{
			position: relative;
			img{
				width: calc(100%);
			}
		}
	}
}